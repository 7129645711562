<template>
	<div>
		<!-- 公共头部 -->
		<Header ></Header>
		
		<div class="section1">
			<img class="pic" :src="goodsInfo.img" />
			<div class="content">
				<h2 class="title">{{goodsInfo.title}}</h2>
				<div class="desc">{{goodsInfo.description}}</div>
				<div class="price">积分：<font>{{goodsInfo.money}}</font></div>
			</div>
		</div>
		<div class="section2">
			<h2 class="cate-title">详细信息</h2>
			<div class="singlepage" v-html="goodsInfo.content"></div>
		</div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '积分兑换',
				goodsInfo: ''
			}
		},
		activated() {
			this.getGoodsInfo();
		},
		deactivated() {
			this.goodsInfo = '';
		},
		methods: {
			async getGoodsInfo() {
				var id = this.$route.query.id;
				var data = {
					id
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/integral/goodsDetail', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('兑换商品详情：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var goodsInfo = res.data;
					if(goodsInfo['img'] && goodsInfo['img'].substring(0,4) != 'http'){
						goodsInfo['img'] = this.$store.state.http + goodsInfo['img'];
					}
					this.goodsInfo = goodsInfo;
				} else {
					this.goodsInfo = '';
				}
			}
		},
	};
</script>

<style scoped>
	.section1{
		font-size: 0;
	}
	.section1 .pic{
		width: 100%;
	}
	.section1 .content{
		background: #ffffff;
		padding: 0.3rem;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.section1 .content .title{
		font-size: 0.32rem;
		color: #000000;
	}
	.section1 .content .desc{
		font-size: 0.24rem;
		color: #000000;
		line-height: 0.42rem;
		margin-top: 0.24rem;
	}
	.section1 .content .price{
		font-size: 0.26rem;
		color: #333333;
		text-align: right;
		margin-top: 0.3rem;
	}
	.section1 .content .price font{
		font-size: 0.32rem;
		color: #ff6000;
	}
	
	.section2{
		margin-top: 0.33rem;
		margin-bottom: 0.7rem;
	}
	.section2 .cate-title{
		height: 0.9rem;
		display: flex;
		align-items: center;
		font-size: 0.32rem;
		color: #333333;
		padding-left: 0.5rem;
		position: relative;
	}
	.section2 .cate-title::before{
		content: '';
		display: inline-block;
		position: absolute;
		width: 0.05rem;
		height: 0.26rem;
		background: #ff6000;
		left: 0.3rem;
		top: 50%;
		transform: translate(0,-50%);
		-ms-transform: translate(0,-50%);
		-o-transform: translate(0,-50%);
		-moz-transform: translate(0,-50%);
		-webkit-transform: translate(0,-50%);
	}
	.section2 .singlepage{
		font-size: 0.24rem;
		color: #333333;
		line-height: 0.44rem;
		background: #ffffff;
		padding: 0.3rem;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
</style>